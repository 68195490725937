<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:597px">
        <div>
          <div>
            <h3 v-if="hasMultiLocations()">Utsolgt hos {{ location.name }}</h3>
            <h3 v-else>Utsolgt</h3>

            <div v-if="soldOutItems.length > 0" class="mt-5 mb-5 soldOutContainer">
                <p>Følgende produkter er utsolgte. Trykk på knappen "Tilgjengelig" for å gjøre produkt tilgjengelig for salg igjen.</p>

                <div v-for="soldOutItem in soldOutItems" :key="soldOutItem.id">
                  <div class="item">
                    <div class="item-col-right">
                      <div class="flex-box">
                        <div>
                          <span class="item-title">{{ getItemMenuNr(soldOutItem.itemId) }} &nbsp;&nbsp;  {{ getItemName(soldOutItem.itemId) }} </span>
                        </div>
                        <div class="cart-buttons-panel">
                          <button class="bo_btn ms-3" @click="unmarkAsSoldOut(soldOutItem)"> Tilgjengelig </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <p class="mt-4">Nedenfor kan du velge produkter som du vil merke som utsolgte og trykke på knappen "Merk som utsolgt".</p>
            <div class="clearfix sticky-top soldOutButtonsStickyPanel">
              <div class="float-start search-box-fix">
                <SearchInput v-model="searchQuery" :shortcutIcon="false" @update:modelValue="search()"/>
              </div>
              <div class="float-end">
                  <button v-if="selectedItems.length > 0" class="bo_btn ms-1" @click="markAsSoldOut()"> Merk som utsolgt ({{ selectedItems.length }})</button>
                  <button v-else class="bo_btn ms-1" disabled="disabled"> Merk som utsolgt </button>
              </div>
            </div>

            <div v-if="filteredItems.length == 0">
                <div v-for="itemsOfCategory in itemsOfCategories" :key="itemsOfCategory.id" class="bo_items-of-category">
                  <div class="bo_items_of_category_panel">
                    <span class="bo_items-of-category-header"> {{ itemsOfCategory.name }} </span>
                  </div>

                  <div v-for="item in itemsOfCategory.items" :key="item.id">
                    <div class="item">
                      <div class="item-col-left">
                        <span> <img :src="item.imageUrl" width="100" height="100"/> </span>
                      </div>
                      <div class="item-col-right">
                        <div class="flex-box">
                          <div>
                            <span class="item-title">{{ item.menuNr }} &nbsp;&nbsp;  {{item.name}} </span>
                            <br/>
                            <span class="description">{{item.description}}</span><br/>
                          </div>
                          <div class="cart-buttons-panel">
                            <input type="checkbox" :name="item.id" :value="item.id" :checked="selectedItemsContains(item)" @click="updateSelectedItems(item)" class="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div v-else>
              <div v-for="item in filteredItems" :key="item.id">
                <div class="item">
                  <div class="item-col-left">
                    <span> <img :src="item.imageUrl" width="100" height="100"/> </span>
                  </div>
                  <div class="item-col-right">
                    <div class="flex-box">
                      <div>
                        <span class="item-title">{{ item.menuNr }} &nbsp;&nbsp;  {{item.name}} </span>
                        <br/>
                        <span class="description">{{item.description}}</span><br/>
                      </div>
                      <div class="cart-buttons-panel">
                        <input type="checkbox" :name="item.id" :value="item.id" :checked="selectedItemsContains(item)" @click="updateSelectedItems(item)" class="checkbox" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import DashboardService from "@/services/dashboard/DashboardService";
import ProductService from "@/services/product/ProductService";
import ConfirmDialogue from "@/components/other/ConfirmDialogue.vue";
import SearchInput from "vue-search-input";
import LocationUtil from "@/util/LocationUtil";
//import levenshtein from "js-levenshtein";

export default {
    components: {ConfirmDialogue, LoadingSpinner, SearchInput},
    data() {
      return {
        loading: false,

        location: {},
        locationId: '',
        selectedItems: [],
        items: [],
        itemsOfCategories: [],
        soldOutItems: [],
        searchQuery: '',
        filteredItems: []
      };
    },
    mounted() {
      this.locationId = this.$route.query.locationId;
      DashboardService.getDataForSoldOutAdminGui(this.locationId).then(
          (response) => {
            let location = response.data.location;
            let categories = response.data.categories;
            let items = response.data.items;
            let soldOutItems = response.data.soldOutItems;
            this.location = location;
            this.items = items;
            this.itemsOfCategories = this.getItemsOfCategories(categories, items);
            this.soldOutItems = soldOutItems;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getItem(itemId){
        for (let i = 0; i < this.items.length; i++) {
          let item = this.items[i];
          if(item.id == itemId){
            return item;
          }
        }
        return null;
      },
      getItemName(itemId){
          let item = this.getItem(itemId);
          if(item != null){
            return item.name;
          }
          return '';
      },
      getItemMenuNr(itemId){
        let item = this.getItem(itemId);
        if(item != null){
          return item.menuNr;
        }
        return '';
      },
      getItemsOfCategories(categories, items){
        let itemsOfCategories = [];
        for (var i = 0; i < categories.length; i++) {
          var category = categories[i];
          var tempItems = [];
          for (var x = 0; x < items.length; x++) {
            var item = items[x];
            if(item.categoryId == category.id){
              tempItems.push(item)
            }
          }
          //Remove already found items of category
          items = items.filter(function(item) {
            return tempItems.indexOf(item) === -1;
          });

          //Sort tempItems by asc sortOrder
          tempItems.sort(function(a, b) {
            if(a.sortOrder == b.sortOrder) { return 0; }
            if(a.sortOrder > b.sortOrder) {
              return 1;
            }else {
              return -1;
            }
          });

          let itemsOfCategory = {
            id  : category.id,
            sortOrder: category.sortOrder,
            name : category.name,
            items : tempItems
          };
          itemsOfCategories.push(itemsOfCategory);
        }

        //Sort itemsOfCategory by asc sortOrder
        itemsOfCategories.sort(function(a, b) {
          if(a.sortOrder == b.sortOrder) { return 0; }
          if(a.sortOrder > b.sortOrder) {
            return 1;
          }else {
            return -1;
          }
        });

        return itemsOfCategories;
      },
      selectedItemsContains(Item){
        for (let i = 0; i < this.selectedItems.length; i++) {
          let selectedItem = this.selectedItems[i];
          if(selectedItem.id == Item.id){
            return true;
          }
        }
        return false;
      },
      updateSelectedItems(item){
        if(this.selectedItemsContains(item)){
          //Already selected, remove it
          for (let i = 0; i < this.selectedItems.length; i++) {
            if (this.selectedItems[i].id == item.id) {
              this.selectedItems.splice(i, 1);
              break;
            }
          }
        }else {
          //Add it
          this.selectedItems.push(item);
        }
      },
      unmarkAsSoldOut(soldOutItem){
        this.loading = true;
        ProductService.unmarkProductsAsSoldOut(soldOutItem.merchant, soldOutItem.id).then(
            (response) => {
              if(response.data != null){
                this.$router.go(); //Reload current page
              }else {
                console.log("unmarkProductsAsSoldOut failed");
              }
            },
            (error) => {
              console.log(error);
            }
        );
      },
      async markAsSoldOut(){
        const ok = await this.$refs.confirmDialogue.show({
          title: this.getSoldOutTittel(),
          message: this.getSoldOutProductsText(),
          okButton: 'Ja, merk som utsolgt',
          cancelButton: 'Avbryt'
        })
        if(ok) {
          this.loading = true;

          let payload = {
            locationId: this.locationId,
            items: this.selectedItems
          }
          ProductService.markProductsAsSoldOut(payload).then(
              (response) => {
                if(response.data != null){
                  this.$router.push({ path: 'overview' });
                }else {
                  console.log("Deleting item failed");
                }
              },
              (error) => {
                console.log(error);
              }
          );
        } else {
          //Cancel, do nothing
        }
      },
      getSoldOutTittel(){
        if(this.hasMultiLocations()){
            return 'Utsolgt hos ' + this.location.name;
        }else {
            return 'Utsolgt';
        }
      },
      getSoldOutProductsText(){
        let text = '<div class="mb-3">Vil du merke følgende produkter som utsolgte?</div>';
        for (let i = 0; i < this.selectedItems.length; i++) {
          let item = this.selectedItems[i];
          text = text + '<strong>' + item.name + '</strong>' + '<br/>';
        }
        return text
      },
      search() {
        if(this.searchQuery.trim() == ''){
          this.filteredItems = [];
        } else {
          //const MIN_DISTANCE = 5;
          let filteredItems = this.items.filter((item) => {
            let name = item.name.toLowerCase();
            let q = this.searchQuery.trim().toLowerCase();
            return name.toLowerCase().includes(q.toLowerCase());
            /** TODO: levenshtein fix to give better result later
            const distance = levenshtein(name, q);
            return distance <= MIN_DISTANCE;
             */
          });

          this.filteredItems = filteredItems;
        }
      }
    }
  };
</script>

<style scoped>
.checkbox{
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
</style>

<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Betalingsmiddel</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td colSpan="4"><b>Kontant</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Total kontant</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Vipps</b></td>
      </tr>
      <tr>
        <td>Ta med </td>
        <td>{{totalVippsPickupCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalVippsPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{totalVippsDineInCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalVippsDineInPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{totalVippsDeliveryCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalVippsDeliveryPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td>Total Vipps</td>
        <td>{{vippsTotalCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalVippsPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Kort</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>{{totalCardPickupCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalCardPickupPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{totalCardDineInCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalCardDineInPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Levere</td>
        <td>{{totalCardDeliveryCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalCardDeliveryPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Total kort</td>
        <td>{{cardTotalCount}}</td>
        <td></td>
        <td>{{ displayTwoDecimals(totalCardPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td class="bold">Total salg</td>
        <td class="bold">{{totalPaymentCount}}</td>
        <td></td>
        <td class="bold">{{ displayTwoDecimals(totalPayment) }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import TurnoverReportUtil from "@/util/TurnoverReportUtil";
  import MathUtil from "@/util/MathUtil";

  export default {
    props: {
      zReports: Array,
      vat: Object
    },
    computed: {
      totalVippsPickupCount() {
        return TurnoverReportUtil.getTotalVippsPickupCount(this.zReports);
      },
      totalVippsPickupPaymentsTotalAmount() {
        var vippsPickupPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          vippsPickupPaymentsTotalAmount = vippsPickupPaymentsTotalAmount + zReport.vippsPickupPaymentsTotalAmount;
        }
        return vippsPickupPaymentsTotalAmount;
      },
      totalVippsDineInCount() {
        return TurnoverReportUtil.getTotalVippsDineInCount(this.zReports);
      },
      totalVippsDineInPaymentsTotalAmount() {
        var vippsDineInPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          let vippsDineInPaymentsTotalAmountTmp = zReport.vippsDineInPaymentsTotalAmount ? zReport.vippsDineInPaymentsTotalAmount : 0;
          vippsDineInPaymentsTotalAmount = vippsDineInPaymentsTotalAmount + vippsDineInPaymentsTotalAmountTmp;
        }
        return vippsDineInPaymentsTotalAmount;
      },
      totalVippsDeliveryCount() {
        return TurnoverReportUtil.getTotalVippsDeliveryCount(this.zReports);
      },
      totalVippsDeliveryPaymentsTotalAmount() {
        var vippsDeliveryPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          vippsDeliveryPaymentsTotalAmount = vippsDeliveryPaymentsTotalAmount + zReport.vippsDeliveryPaymentsTotalAmount;
        }
        return vippsDeliveryPaymentsTotalAmount;
      },
      vippsTotalCount() {
        return TurnoverReportUtil.getVippsTotalCount(this.zReports);
      },
      totalVippsPaymentsTotalAmount() {
        var vippsPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          let vippsDineInPaymentsTotalAmountTmp = zReport.vippsDineInPaymentsTotalAmount ? zReport.vippsDineInPaymentsTotalAmount : 0;
          vippsPaymentsTotalAmount = vippsPaymentsTotalAmount + zReport.vippsPickupPaymentsTotalAmount + vippsDineInPaymentsTotalAmountTmp + zReport.vippsDeliveryPaymentsTotalAmount;
        }
        return vippsPaymentsTotalAmount;
      },

      totalCardPickupCount() {
        return TurnoverReportUtil.getTotalCardPickupCount(this.zReports);
      },
      totalCardPickupPaymentsTotalAmount() {
        var cardPickupPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          cardPickupPaymentsTotalAmount = cardPickupPaymentsTotalAmount + zReport.cardPickupPaymentsTotalAmount;
        }
        return cardPickupPaymentsTotalAmount;
      },
      totalCardDineInCount() {
        return TurnoverReportUtil.getTotalCardDineInCount(this.zReports);
      },
      totalCardDineInPaymentsTotalAmount() {
        var cardDineInPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          let cardDineInPaymentsTotalAmountTmp = zReport.cardDineInPaymentsTotalAmount ? zReport.cardDineInPaymentsTotalAmount : 0;
          cardDineInPaymentsTotalAmount = cardDineInPaymentsTotalAmount + cardDineInPaymentsTotalAmountTmp;
        }
        return cardDineInPaymentsTotalAmount;
      },
      totalCardDeliveryCount() {
        return TurnoverReportUtil.getTotalCardDeliveryCount(this.zReports);
      },
      totalCardDeliveryPaymentsTotalAmount() {
        var cardDeliveryPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          cardDeliveryPaymentsTotalAmount = cardDeliveryPaymentsTotalAmount + zReport.cardDeliveryPaymentsTotalAmount;
        }
        return cardDeliveryPaymentsTotalAmount;
      },
      cardTotalCount() {
        return TurnoverReportUtil.getCardTotalCount(this.zReports);
      },
      totalCardPaymentsTotalAmount() {
        var cardPaymentsTotalAmount = 0;
        for (var i = 0; i < this.zReports.length; i++) {
          var zReport = this.zReports[i].cagistahospitality;
          let cardDineInPaymentsTotalAmount = zReport.cardDineInPaymentsTotalAmount ? zReport.cardDineInPaymentsTotalAmount : 0;
          cardPaymentsTotalAmount = cardPaymentsTotalAmount + zReport.cardPickupPaymentsTotalAmount + cardDineInPaymentsTotalAmount + zReport.cardDeliveryPaymentsTotalAmount;
        }
        return cardPaymentsTotalAmount;
      },

      totalPaymentCount() {
        return (TurnoverReportUtil.getVippsTotalCount(this.zReports) + TurnoverReportUtil.getCardTotalCount(this.zReports));
      },
      totalPayment() {
        return (this.totalVippsPaymentsTotalAmount + this.totalCardPaymentsTotalAmount);
      }
    },
    methods: {
      displayTwoDecimals(num){
        if(num > 0 || num < 0){
          return MathUtil.displayTwoDecimals(num);
        }else {
          return num;
        }
      }
    }
  }
</script>

<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div v-if="!loading" class="mt-4 mb-4" style="max-width:560px">
        <OrderDetail :dataForOrderDetail="dataForOrderDetail"/>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import OrderService from "@/services/order/OrderService";
import OrderDetail from "@/components/order/OrderDetail.vue";

export default {
  components: {OrderDetail, LoadingSpinner},
  data() {
    return {
      loading: true,

      orderDocumentId: '',
      dataForOrderDetail: {}
    };
  },
  mounted() {
    this.orderDocumentId = this.$route.query.orderDocumentId;
    OrderService.getDataForOrderDetailGui(this.orderDocumentId).then(
        (response) => {
          this.dataForOrderDetail = response.data;

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  }
}
</script>


import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/css/navbar.css";
import "./assets/css/backoffice.css";
import "./assets/css/vue-search-input.css";
import "./assets/css/timepicki.css";
import { FontAwesomeIcon } from './plugins/font-awesome'

createApp(App)
    .use(router)
    .use(store)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div class="bo_categories" style="max-width:597px">
        <h3>Legg til valg i gruppen "{{addOnGroup.name}}"</h3>
        <div class="mt-5">
          <span class="ingredient-title">Navn</span>
          <input class="form-control" type="text" v-model="addOn.name"/>
        </div>

        <div class="ingredient-title mt-4">Pris</div>
        <div class="row mt-2">
            <div class="mt-3"><strong> TA MED/TAKEAWAY </strong></div>
            <div class="col-sm-6 d-inline-block">
              <input class="form-control" type="number" min="0" v-model="price" @input="updatePriceWithVat()" />
              <span class="ms-2">(uten MVA)</span>
            </div>
            <div class="col-sm-6 d-inline-block">
              <input class="form-control" type="number" min="0" v-model="priceWithVat" @input="updatePrice()" />
              <span class="ms-2">
                (med MVA for TAKEAWAY, {{ vat.takeOut }}%)
              </span>
            </div>
        </div>
        <div v-if="useFunctionality === 'POS' || useFunctionality === 'ALL'" class="row mt-2">
            <div class="mt-3"><strong> SPISE HER </strong></div>
            <div class="col-sm-6 d-inline-block">
              <input class="form-control" type="number" min="0" v-model="priceDineIn" @input="updatePriceDineInWithVat()" />
              <span class="ms-2">(uten MVA)</span>
            </div>
            <div class="col-sm-6 d-inline-block">
                <input class="form-control" type="number" min="0" v-model="priceDineInWithVat" @input="updatePriceDineIn()" />
                <span class="ms-2">
                      (med MVA for SPISE HER, {{ vat.dineIn }}%)
                </span>
            </div>
        </div>

        <div v-if="addOnAllowMultipleTimes" :class="addOn.allowMultipleTimes ? 'form-control' : ''">
          <div class="form-switch-wrapper mt-2 mb-3">
            <label class="form-switch">
              <div class="form-switch-container">
                <span class="bold me-2">Tillatt å velge flere ganger</span>
                <input type="checkbox" v-model="addOn.allowMultipleTimes" />
                <i></i>
              </div>
            </label>
          </div>
          <div v-if="addOn.allowMultipleTimes" class="col-sm-8">
              <div class="col-sm-8">Maksimalt tillatt antall</div>
              <input type="number" min="2" max="100" v-model="addOn.maximum" />
          </div>
        </div>

        <div class="bo_hr mt-4">
          <hr/>
        </div>

        <div class="mb-5">
          <button v-if="activateSaveButton" type="button" class="bo_btn" @click="saveAddOn"> Lagre </button>
          <button v-else type="button" class="bo_btn" @click="saveAddOn" disabled="true"> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import ProductService from "@/services/product/ProductService";
import ItemUtil from "@/util/ItemUtil";

export default {
  components: {LoadingSpinner},
  data() {
    return {
      loading: false,

      addOnGroup: {},
      vat: {},

      price: 0,               //Takeaway price without VAT
      priceWithVat: "",       //Takeaway price with VAT
      priceDineIn: "",        //Dine-in price without VAT
      priceDineInWithVat: "", //Dine-in price with VAT

      addOn: {
        type: "add-on",
        merchant: "",
        addOnGroupId: "",
        name: "",
        price: 0,
        priceDineIn: 0,
        allowMultipleTimes: false,
        maximum: 100,
        sortOrder: -1
      }
    };
  },
  mounted() {
    this.addOn.addOnGroupId = this.$route.query.addOnGroupId;
    ProductService.getDataForAddOnAdminGui(this.addOn.addOnGroupId).then(
        (response) => {
          let dataForGui = response.data;
          this.addOnGroup = dataForGui.addOnGroup;
          this.vat = dataForGui.vat;
          this.addOn.merchant = this.addOnGroup.merchant;

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  computed: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    },
    activateSaveButton(){
      if(this.addOn.name.length > 0){
        return true;
      } else {
        return false;
      }
    },
    addOnAllowMultipleTimes(){
      return MerchantUtil.addOnAllowMultipleTimes();
    }
  },
  methods: {
    updatePriceWithVat(){
      let priceWithVat = this.price + (this.price / 100 * this.vat.takeOut);
      this.priceWithVat = Math.round(priceWithVat);
    },
    updatePriceDineInWithVat(){
      let priceDineInWithVat = this.priceDineIn + (this.priceDineIn / 100 * this.vat.dineIn);
      this.priceDineInWithVat = Math.round(priceDineInWithVat);
    },
    updatePrice(){
      this.price = ItemUtil.getPriceWithoutVat(this.vat.takeOut, this.priceWithVat);
    },
    updatePriceDineIn(){
      this.priceDineIn = ItemUtil.getPriceWithoutVat(this.vat.dineIn, this.priceDineInWithVat);
    },
    saveAddOn(){
      this.loading = true;

      this.addOn.price = this.price;
      this.addOn.priceDineIn = this.priceDineIn;
      this.createAddOn(this.addOn);
    },
    createAddOn(addOn){
      console.log('addOn: ' + JSON.stringify(addOn));
      ProductService.createAddOn(addOn).then(
          (response) => {
            let documentId = response.data;
            if(documentId){
              this.$router.push({ path: 'products'});
            } else {
              console.log("Creating addOn failed");
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
      );
    }
  }
};
</script>
